var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-app":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"ml-4",staticStyle:{"color":"#e33354"},attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.cardTitle)+" ")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('FORMS.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[(_vm.permissionCan('upload'))?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.handleFileUpload}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.openFileInput}},[_vm._v("Fájl feltölés")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-select',{attrs:{"items":_vm.languages,"disabled":_vm.languages.length == 1},on:{"change":function($event){return _vm.fetchModel()}},scopedSlots:_vm._u([{key:"selection",fn:function(slotProps){return [_c('span',{staticClass:"symbol symbol-20 mr-3"},[_c('img',{attrs:{"src":_vm.locale.flag,"alt":""}})]),_vm._v(" "+_vm._s(slotProps.item.name)+" ")]}},{key:"item",fn:function(slotProps){return [_c('span',{staticClass:"symbol symbol-20 mr-3"},[_c('img',{attrs:{"src":slotProps.item.flag,"alt":""}})]),_c('span',{staticClass:"navi-text"},[_vm._v(_vm._s(slotProps.item.name))])]}}],null,false,618528696),model:{value:(_vm.locale),callback:function ($$v) {_vm.locale=$$v},expression:"locale"}})],1)],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredCollection,"search":_vm.search,"loading":_vm.loadingTable,"footer-props":{
          'items-per-page-options': [100],
        }},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th',[_c('v-select',{attrs:{"items":['Összes', 'Üres', ..._vm.intezmenyNeveArray]},model:{value:(_vm.intezmenyNeveFilter),callback:function ($$v) {_vm.intezmenyNeveFilter=$$v},expression:"intezmenyNeveFilter"}})],1),_c('th',[_c('v-select',{attrs:{"items":['Összes', 'Üres', ..._vm.intezmenyKarArray]},model:{value:(_vm.intezmenyKarFilter),callback:function ($$v) {_vm.intezmenyKarFilter=$$v},expression:"intezmenyKarFilter"}})],1),_c('th'),_c('th'),_c('th',[_c('v-select',{attrs:{"items":['Összes', 'Üres', ..._vm.partnerIntezmenyOrszagaArray]},model:{value:(_vm.partnerIntezmenyOrszagaFilter),callback:function ($$v) {_vm.partnerIntezmenyOrszagaFilter=$$v},expression:"partnerIntezmenyOrszagaFilter"}})],1),_c('th',[_c('v-select',{attrs:{"items":['Összes', 'Üres', ..._vm.partnerIntezmenyNeveArray]},model:{value:(_vm.partnerIntezmenyNeveFilter),callback:function ($$v) {_vm.partnerIntezmenyNeveFilter=$$v},expression:"partnerIntezmenyNeveFilter"}})],1),_c('th'),_c('th'),_c('th'),_c('th',[_c('v-select',{attrs:{"items":['Összes', 'Üres', ..._vm.egyuttmukodesiTeruletArray]},model:{value:(_vm.egyuttmukodesiTeruletFilter),callback:function ($$v) {_vm.egyuttmukodesiTeruletFilter=$$v},expression:"egyuttmukodesiTeruletFilter"}})],1),_c('th'),_c('th'),_c('th',[_c('v-select',{attrs:{"items":['Összes', 'Üres', ..._vm.egyuttmukodesTipusaArray]},model:{value:(_vm.egyuttmukodesTipusaFilter),callback:function ($$v) {_vm.egyuttmukodesTipusaFilter=$$v},expression:"egyuttmukodesTipusaFilter"}})],1),_c('th'),_c('th'),_c('th'),_c('th',[_c('v-select',{attrs:{"items":[
                    'Összes',
                    'Üres',
                    ..._vm.kapcsolatMegoszthatosagaArray,
                  ]},model:{value:(_vm.kapcsolatMegoszthatosagaFilter),callback:function ($$v) {_vm.kapcsolatMegoszthatosagaFilter=$$v},expression:"kapcsolatMegoszthatosagaFilter"}})],1)])])]}}])})],1),_c('SnackBarInfoComponent',{attrs:{"snackbarInfo":_vm.snackbarInfo}}),_c('DeleteModalDialog',{attrs:{"dialogDelete":_vm.dialogDelete},on:{"closeDelete":_vm.handleCloseDelete,"deleteItemConfirm":_vm.handleDeleteItemConfirm}})],1),_c('v-overlay',{attrs:{"value":_vm.loader}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }